import React, { memo, useState } from 'react';
import useMedia from '~/hooks/useMedia';
import { css } from '@emotion/react';
import { flex, jc } from '~/modules/AppLayout/FlexGridCss';
import { PercentagePalette } from '~/modules/monitors/PercentagePalette';
import { globalGrey, WHITE } from '~/modules/AppLayout/Colors';
import { HighlightText } from '~/components/HighlightText';
import { FormControlLabel, Switch, switchClasses } from '@mui/material';
import { SignalrOsFuturesQuote } from '../SDK/socket/SignalrOsFuturesQuote';
import { fontWeight600 } from '~/css/font';
import { useMount, useUnmount } from 'react-use';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { flatMap } from 'lodash';
/** 本頁固定的商品集合 */
const signalrSymbols = {
    osFutures1: ['TSEA', 'TX-1', 'TWN-1', 'YM-1', 'NQ-1', 'ES-1', 'RTY-1'],
    osFutures2: ['NK-1', 'CN-1', 'HSI-1', 'HCEI-1', 'IN-1', 'FDX-1', 'FCH-1'],
    osFutures3: ['CL-1', 'NG-1', 'HG-1', 'SI-1', 'GC-1', 'PL-1'],
    osFutures4: ['S-1', 'W-1', 'C-1', 'KC-1', 'SB-1', 'CC-1', 'BO-1'],
    forex: ['DX-1', 'AD-1', 'BP-1', 'CD-1', 'EC-1', 'JY-1', 'SF-1'],
    usStock1: ['AAPL', 'MSFT', 'GOOGL', 'AMZN', 'NVDA', 'META', 'TSLA'],
    usStock2: ['TSM', 'UNH', 'XOM', 'JNJ', 'JPM', 'V', 'PG'],
};
export const OsFuturesListTile = memo(function OsFuturesListTile() {
    const { isPc, isPhone } = useMedia();
    const [baseRefIsTodayOpen, setBaseRefIsTodayOpen] = useState(false);
    const toggleChecked = () => {
        setBaseRefIsTodayOpen(prev => !prev);
    };
    const boxesCss = css `
    ${flex.wrap.default}
    ${isPc ? jc.flexEnd : jc.flexStart}
  margin: 8px 4px;
    & > * {
      margin-right: 8px;
    }
  `;
    const categoryFlexbox = css `
    ${isPc ? flex.v.default : flex.wrap.default}
  `;
    useMount(() => {
        signalrStore2.addQuote(flatMap(signalrSymbols));
    });
    useUnmount(() => {
        signalrStore2.removeQuote(flatMap(signalrSymbols));
    });
    return (<div css={css `
        margin: 8px 4px;
      `}>
      <div css={boxesCss}>
        <div css={toggleBoxCss}>
          <p css={switchHeaderCss}>漲跌比例參考點</p>
          <FormControlLabel onChange={toggleChecked} checked={baseRefIsTodayOpen} control={<Switch name='gilad'/>} label={baseRefIsTodayOpen ? '今日開盤' : '昨日收盤'}/>
        </div>
        <PercentagePalette isFutures={true}/>

        <HighlightText background={'white'} color={'black'} content={'$價格'} border={'1px solid black'}/>
      </div>
      <div css={css `
          ${isPc ? flex.h.default : flex.v.default}
          ${isPhone && 'padding-bottom: 72px;'}
        `}>
        <div>
          <p css={categoryTitleCss}> 指數期貨</p>
          <div css={categoryFlexbox}>
            {signalrSymbols.osFutures1.map(s => (<SignalrOsFuturesQuote baseOnTodayOpen={baseRefIsTodayOpen} symbol={s} key={s}/>))}
          </div>
        </div>

        <div>
          {isPc && <p css={categoryTitleCss}>{'\u2003'}</p>}
          <div css={categoryFlexbox}>
            {signalrSymbols.osFutures2.map(s => (<SignalrOsFuturesQuote baseOnTodayOpen={baseRefIsTodayOpen} symbol={s} key={s}/>))}
          </div>
        </div>
        <div>
          <p css={categoryTitleCss}>能源金屬</p>
          <div css={categoryFlexbox}>
            {signalrSymbols.osFutures3.map(s => (<SignalrOsFuturesQuote baseOnTodayOpen={baseRefIsTodayOpen} symbol={s} key={s}/>))}
          </div>
        </div>
        <div>
          <p css={categoryTitleCss}>農產品</p>
          <div css={categoryFlexbox}>
            {signalrSymbols.osFutures4.map(s => (<SignalrOsFuturesQuote baseOnTodayOpen={baseRefIsTodayOpen} symbol={s} key={s}/>))}
          </div>
        </div>
        <div>
          <p css={categoryTitleCss}>外匯</p>
          <div css={categoryFlexbox}>
            {signalrSymbols.forex.map(s => (<SignalrOsFuturesQuote baseOnTodayOpen={baseRefIsTodayOpen} symbol={s} key={s}/>))}
          </div>
        </div>
        <div>
          <p css={categoryTitleCss}>美股</p>
          <div css={categoryFlexbox}>
            {signalrSymbols.usStock1.map(s => (<SignalrOsFuturesQuote baseOnTodayOpen={baseRefIsTodayOpen} symbol={s} key={s}/>))}
          </div>
        </div>
        <div>
          {isPc && <p css={categoryTitleCss}>{'\u2003'}</p>}
          <div css={categoryFlexbox}>
            {signalrSymbols.usStock2.map(s => (<SignalrOsFuturesQuote baseOnTodayOpen={baseRefIsTodayOpen} symbol={s} key={s}/>))}
          </div>
        </div>
      </div>
    </div>);
});
const switchHeaderCss = css `
  ${fontWeight600};
  font-size: 16px;
  margin: 0;
  margin-right: 8px;
`;
const categoryTitleCss = css `
  color: ${WHITE};
  font-size: 1.5rem;
  ${fontWeight600};
  margin: 8px;
`;
const toggleBoxCss = css `
  ${flex.h.crossCenter};
  margin-right: auto;
  color: ${globalGrey.g100};
  & .${switchClasses.track} {
    background-color: ${globalGrey.g100};
  }
`;
